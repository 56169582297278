<template>
  <div class="header">
    <!-- <div class="user_box" v-if="$route.name === 'home'"> -->
    <div class="user_box">
      <div class="w1200">
        <div class="left" v-if="!userLogin">
          <router-link to="/Login"
            ><svg-icon icon-class="home_user" /> 请登录</router-link
          >
          <!-- <router-link to="/Login/Register"
            ><svg-icon icon-class="home_user_a" /> 注册</router-link
          > -->
        </div>
        <div class="left" v-else>
          <p>
            您好，{{ userInfo.userEntity.telNum }}
            <span @click="logOut" style="cursor: pointer">退出</span>
          </p>
        </div>
        <div class="right">
          <span style="margin: 0; color: #666">客服电话：0755-33297563</span>
          <span>|</span>
          <router-link
            to="/PersonalCenter"
            v-if="userInfo.userEntity.userType === 'PURCHASE'"
            >我的订单</router-link
          >
          <span v-if="userInfo.userEntity.userType === 'PURCHASE'">|</span>
          <router-link to="">技术支持</router-link>
          <span>|</span>
          <router-link to="">客户服务</router-link>
        </div>
      </div>
    </div>
    <div class="header-cont">
      <div class="top">
        <div class="left" @click="goHomePage">
          <img src="@/assets/images/logo.png" alt="正品汇logo" class="logo" />
          <div class="title">
            <h1>正品汇认证交易平台</h1>
            <h2>Zhengpinhui authentication trading platform</h2>
          </div>
        </div>
        <div class="seath">
          <p @click="switchShow = !switchShow">
            {{ flag ? "店铺" : "商品"
            }}<svg-icon
              icon-class="ic-arrow-a"
              :style="
                switchShow
                  ? 'transform: rotateZ(-180deg);'
                  : 'transform: rotateZ(0deg);'
              "
              style="transition: all 0.2s"
            />
          </p>
          <transition name="el-zoom-in-top">
            <ul class="switch-box" v-show="switchShow">
              <li
                v-for="list in switchContent"
                :key="list.flag"
                @click="switchMethods(list.flag)"
              >
                {{ list.content }}
              </li>
            </ul>
          </transition>
          <div></div>
          <input
            type="text"
            placeholder="请输入名称、货号、规格、品牌等进行搜索"
            v-model="searchValue"
            @focus="
              searchResults.length ? (searchShow = true) : (searchShow = false)
            "
            @blur="outInput"
            @input="contentChange"
            @keyup.enter="search"
          />
          <transition name="el-zoom-in-top">
            <ul class="search-all" v-show="searchShow">
              <li
                v-for="(list, i) in searchResults"
                :key="i"
                @click="searchClick(list.name)"
              >
                {{ list.name }}
              </li>
            </ul>
          </transition>
          <button @click="search">
            <svg-icon icon-class="ic-search" />搜索
          </button>
        </div>
        <a class="shoppingCart" href="#/ShoppingCart" target="_blank">
          <svg-icon icon-class="ic-shoppingCart" />
          <p>购物车</p>
          <span
            class="num"
            :style="sum > 99 || userSum > 99 ? 'width:30px' : ''"
            >{{ userLogin ? userSum : sum | SUM }}</span
          >
        </a>
      </div>
      <div class="nav" v-if="PersonalCenter">
        <router-link to="/Category" class="category">
          <svg-icon icon-class="ic-categorize" />全部商品分类
        </router-link>
        <ul>
          <li v-for="(list, index) in nav" :key="index">
            <router-link :to="list.herf">{{ list.title }}</router-link>
          </li>
          <!-- <li>
            <a @click.prevent="tz" href="">店长招募</a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";

// import { _debounce } from "@/utils/untils.js";
export default {
  name: "app-header",
  components: {},
  computed: {
    ...mapState("user", ["sum", "userData", "isLogin"]),
    ...mapState("personalUser", [
      "userLogin",
      "userEntity",
      "userSum",
      "userInfo",
    ]),
  },
  data() {
    return {
      nav: [
        { title: "首页", herf: "/Home" },
        { title: "轨迹溯源", herf: "/TrackBack" },
        { title: "物流配送", herf: "/Logistics" },
        { title: "银行服务", herf: "/BankingServices" },
        { title: "保险服务", herf: "/InsuranceServices" },
        // { title: "技术服务", herf: "/" },
        // { title: "合作加盟", herf: "/" },
      ],
      searchValue: "", //输入框双向绑定
      searchResults: [], //输入框模糊查询列表
      searchShow: false, //输入框显示隐藏
      flag: 0, //店铺商品切换
      switchShow: false, //店铺商品显示隐藏
      switchContent: [
        { content: "商品", flag: 0 },
        { content: "店铺", flag: 1 },
      ], //店铺商品切换内容
      isTrackBack: false,
      PersonalCenter: false,
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        val.path.split("/")[1] === "PersonalCenter"
          ? (this.PersonalCenter = false)
          : (this.PersonalCenter = true);
        val.query.like
          ? (this.searchValue = val.query.like)
          : (this.searchValue = "");
        Number(val.query.flag) && (this.flag = Number(val.query.flag));
        if (val.name === "track-back") {
          this.isTrackBack = true;
        } else {
          this.isTrackBack = false;
        }
      },
      immediate: true,
    },
    searchResults: {
      handler(val) {
        if (!val.length) {
          //判断模糊查询列表有没数据
          this.searchShow = false;
        } else {
          this.searchShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
    searchValue: {
      handler(val) {
        if (val === "") {
          this.searchResults = [];
        }
        if (val.length === 0) {
        }
      },
      deep: true,
      immediate: true,
    },
    userInfo: {
      handler: function (val, oldVal) {
        val.userEntity.telNum =
          val.userEntity.telNum.substr(0, 3) +
          "****" +
          val.userEntity.telNum.substr(7);
      },
      deep: true,
      // immediate: true,
    },
  },
  filters: {
    //处理函数
    SUM(sum) {
      if (sum > 99) {
        return "99+";
      } else {
        return sum;
      }
    },
  },
  created() {
    // process.env.NODE_ENV === "development" &&
    //   Cookies.set("token_personal", "500F2F10-A3B6-4440-9FA3-23A18A934FC7");

    // process.env.NODE_ENV === "development" &&
    //   Cookies.set(
    //     "KAI_HUA_ADMIN_TOKEN",
    //     "089E3BF3-66FA-4EEA-B261-1481762D0EAD"
    //   );
    this.EnterpriseLogin();
    this.UserLogin();
  },
  mounted() {},

  methods: {
    async Search() {
      const data = await this.$API.homePage.inputSearch({
        object: {
          flag: this.flag,
          name: this.searchValue,
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.searchResults = data.data.data;
    },
    //店铺商品切换
    switchMethods(flag) {
      this.flag = flag;
      this.switchShow = false;
      this.searchResults = [];
    },
    //模糊查询点击
    searchClick(val) {
      this.searchValue = val;
      this.searchShow = false;
    },
    //移出输入框延迟消失
    outInput() {
      clearTimeout(a);
      var a = setTimeout(() => {
        this.searchShow = false;
      }, 200);
    },
    //输入框事件
    contentChange() {
      if (!!this.searchValue) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.Search();
        }, 500);
      }
    },
    //搜索跳转
    search() {
      if (this.flag === 0) {
        //查商品
        this.$router.push({
          name: "all-goods",
          query: {
            like: this.searchValue,
            flag: 0,
          },
        });
      } else {
        //查店铺
        this.$router.push({
          name: "store-list",
          query: {
            like: this.searchValue,
            flag: 1,
          },
        });
      }
    },
    //退出登录
    logOut() {
      Cookies.remove("token_personal");
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("userType");
      location.reload();
      // window.location.replace(this.$URL);
    },
    goHomePage() {
      if (!(this.$route.path === "/Home")) {
        this.$router.push({ name: "home" });
      }
    },
    async getEnterpriseId() {
      const data = await this.$API.user.getEnterpriseId({
        creatorIdentityId: "",
        creatorUserId: "",
      });
      this.$store.commit("user/setBrandId", data.data.data.id);
      this.$store.dispatch("user/getCartSum");
    },
    //企业登录
    EnterpriseLogin() {
      let EnterpriseToken = Cookies.get("KAI_HUA_ADMIN_TOKEN"); //获取企业登录TOKEN
      // this.$store.dispatch("user/getUser");
      EnterpriseToken && this.$store.dispatch("user/getUser");
      setTimeout(() => {
        EnterpriseToken && this.getEnterpriseId();
        // this.getEnterpriseId();
      }, 100);
    },
    //消费者登录
    async UserLogin() {
      let UserToken = Cookies.get("token_personal");
      if (UserToken) {
        // let user = await this.$API.personalUser.getUser({});
        // this.$store.commit("personalUser/setUserId", user.data.data.id);
        this.$store.dispatch("personalUser/getUserInfo");
        this.$store.dispatch("personalUser/getShoppingCartSum");

        // let shop = await this.$API.personalUser.getShoppingCartSum({
        //   object: {
        //     userId: user.data.data.id,
        //   },
        //   pageNum: 0,
        //   pageSize: 0,
        // });

        // shop.data.data &&
        //   this.$store.commit("personalUser/getCartSum", shop.data.data.sum);
      }
    },
    //消费者信息
    async getUserInfo() {
      // console.log(this.userId);
      // this.$store.dispatch('personalUser/getUserId')
      // let userInfo = await this.$API.personalUser.getUserData({
      //   object: {
      //     id: this.userId,
      //   },
      // });
      // this.$store.commit("personalUser/setUser", userInfo.data.data.userInfo); //用户信息
    },
    // async getShoppingCartSum() {
    //   const data = await this.$API.order.getShoppingCartSum({
    //     object: {
    //       enterpriseId: this.Id,
    //     },
    //     pageNum: 0,
    //     pageSize: 0,
    //   });
    //   this.sum = data.data.data.sum;
    // },
    tz() {
      if (
        this.userInfo.id &&
        this.userInfo.userEntity.userType === "PURCHASE"
      ) {
        //登录并且是店长
        if (this.userInfo.userDetailEntity.getGoodsName) {
          //完善资料 直接进入
          this.$router.push("/Login/PerfectInformation/Audit");
        } else {
          //未完善资料 弹窗
          this.$alert("恭喜您，注册成功！<br/>下一步，请完善店长资料", "提示", {
            confirmButtonText: "立即完善资料",
            dangerouslyUseHTMLString: true,
            callback: (action) => {
              this.$router.push("/Login/PerfectInformation");
            },
          });
        }
      } else {
        //未登录或团员
        this.$router.push("/Login/ToRegister");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/header.scss";
</style>