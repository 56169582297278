<template>
  <div id="footer" v-if="$route.name != 'mall'" ref="footer">
    <div class="footer-nav">
      <div>
        <div class="nav-list">
          <ul v-for="(item, index) in nav" :key="index">
            <p>{{ item.title }}</p>
            <li v-for="(list, index) in item.children" :key="index">
              {{ list.title }}
            </li>
          </ul>
        </div>
        <div class="QR-code">
          <img v-lazy="require('@/assets/images/QR-code.png')" alt="" />
          <p>关注官方微信公众号</p>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div>
        <div class="copyright-list">
          <router-link to="AboutUs">关于我们</router-link>
          <span></span>
          <a href="/">法律声明</a>
          <span></span>
          <a href="/">诚聘英才</a>
          <span></span>
          <a href="/">投资洽谈</a>
          <span></span>
          <a href="/">联系我们</a>
          <span></span>
          <a href="/">资讯研报</a>
          <span></span>
          <a href="/">工商执照</a>
          <span></span>
          <a href="/">舞弊举报</a>
        </div>
        <div class="copyright-content">
          <span>版权所有2022</span>
          <span>正品汇认证交易平台</span>
          <span
            ><a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402005832"
              ><img src="@/assets/images/copyright.png" style="float: left" />粤公网安备 44030402005832号</a
            ></span
          >
          <span
            ><a href="https://beian.miit.gov.cn/" target="_blank"
              >粤ICP备18094129号</a
            ></span
          >
          <span>增值电信业务经营许可证：粤B2-20191323</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-footer",

  components: {},
  data() {
    return {
      nav: [
        {
          title: "帮助中心",
          children: [
            {
              title: "在线客服",
            },
            {
              title: "买家指南",
            },
            {
              title: "卖家指南",
            },
          ],
        },
        {
          title: "平台规则",
          children: [
            {
              title: "服务协议",
            },
            {
              title: "交易规则",
            },
            {
              title: "隐私条款",
            },
          ],
        },
        {
          title: "合同模板",
          children: [
            {
              title: "采购合同",
            },
            {
              title: "销售合同",
            },
          ],
        },
        {
          title: "支付方式",
          children: [
            {
              title: "在线支付",
            },
            {
              title: "余额支付",
            },
          ],
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/footer.scss";
</style>